import React from 'react';

const Perfil = () => {
    return(
        <div id="perfil-content">
            <img src="images/perfil.jpg" id="perfil" alt="Foto de perfil"/>
            <h1>Olá! Meu nome é Caio</h1>
            <p>Eu sou Desenvolvedor Front-End</p>
        </div>
    )
}

export default Perfil;