import React from "react";
import ReactDOM from "react-dom/client";
import Home from './Home';
import './index.css';

const root = ReactDOM.createRoot(document.querySelector('#root'));

root.render(
    <>
        <Home />
    </>
)